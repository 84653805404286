@import '~normalize.css/normalize';

/** Base **/
* {
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  width: 100vw;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #444444;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
  outline-style: none;

  img {
    border: none;
  }
}

p a, li a, .a {
  transition: color .3s ease;
  text-decoration: underline !important;
  opacity: .6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

h1 {
  margin: 0;
}

p {
  margin: 0 0 8px;
}

/** Sections **/
header, section {
  width: 100%;
  padding: 0;
}

section {
  margin: 40px;

  h2 {
    font-size: 2.5rem;
    color: #9b9b9b;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}
